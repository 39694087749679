<div *ngIf="false" class="row">
  <div class="col-sm-4">
    <br />
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'matTableFilter' | translate }}"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-7"></div>
  <div class="col-sm-1"></div>
</div>

<div class="row mt-4">
  <div class="col-sm-12">
    <div class="d-flex justify-content-start">
      <div>
        <gem-filter
          [title]="'buid' | translate"
          [searchLabel]="'buidSearch' | translate"
          [options]="buidFilterOptions$ | async"
          [value]="getSelectedFilters('buid')"
          [reset]="reset"
          (change)="onFilterChange($event, 'buid')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'partner' | translate"
          [searchLabel]="'partnerSearch' | translate"
          [options]="partnerFilterOptions$ | async"
          [value]="getSelectedFilters('partner')"
          [reset]="reset"
          (change)="onFilterChange($event, 'partner')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'partnerType' | translate"
          [searchLabel]="'partnerTypeSearch' | translate"
          [options]="partnerTypeFilterOptions$ | async"
          [value]="getSelectedFilters('partnerType')"
          [reset]="reset"
          (change)="onFilterChange($event, 'partnerType')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'partnerRole' | translate"
          [searchLabel]="'partnerRoleSearch' | translate"
          [options]="partnerRoleFilterOptions$ | async"
          [value]="getSelectedFilters('role')"
          [reset]="reset"
          (change)="onFilterChange($event, 'role')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'processName' | translate"
          [searchLabel]="'processNameSearch' | translate"
          [options]="processNameFilterOptions$ | async"
          [value]="getSelectedFilters('processName')"
          [reset]="reset"
          (change)="onFilterChange($event, 'processName')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'taskName' | translate"
          [searchLabel]="'taskNameSearch' | translate"
          [options]="taskNameFilterOptions$ | async"
          [value]="getSelectedFilters('name')"
          [reset]="reset"
          (change)="onFilterChange($event, 'name')"
        ></gem-filter>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="md-chips d-flex">
      <div *ngFor="let selectedFilter of selectedFilters | keyvalue" class="d-flex">
        <div *ngFor="let sf of selectedFilter.value" class="d-flex">
          <div class="md-chip md-chip-clickable">
            <span>{{ sf.label | translate }}</span>
            <button
              type="button"
              class="md-chip-remove"
              (click)="onRemoveFilter(selectedFilter.key, sf)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z8 mat-table-responsive-container">
      <table
        mat-table
        matSort
        matTableResponsive
        [dataSource]="dataSource"
        matSortActive="createdTimeStamp"
        matSortDirection="desc"
        (matSortChange)="onMatSortChange($event)"
      >
        <ng-container matColumnDef="buid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "buid" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'buid' | translate"
            [innerHTML]="row.buid | highlightSearch : filterText"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="priority">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "priority" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'priority' | translate"
            (click)="taskRowClicked(row)"
          >
            {{ row.priority }}
          </td>
        </ng-container>

        <ng-container matColumnDef="partner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partner" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'partner' | translate"
            [innerHTML]="row.partner | highlightSearch : filterText"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="partnerType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partnerType" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'partnerType' | translate"
            [innerHTML]="row.partnerType | highlightSearch : filterText"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="taskRole">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partnerRole" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'partnerRole' | translate"
            [innerHTML]="getIdentityRole(row) | highlightSearch : filterText"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="processName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "processName" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'processName' | translate"
            [innerHTML]="row.processName | translate | highlightSearch : filterText"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="taskName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "taskName" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'taskName' | translate"
            [innerHTML]="row.name | translate | highlightSearch : filterText"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="createdTimeStamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "createdTimeStamp" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'createdTimeStamp' | translate"
            (click)="taskRowClicked(row)"
          >
            {{ row.created | date : "dd.MM.YYYY HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="dueTimeDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "dueTimeDate" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'dueTimeDate' | translate"
            (click)="taskRowClicked(row)"
          >
            {{ row.due ? (row.due | date : "dd.MM.YYYY HH:mm") : "-" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customTaskContentOne">
          <th mat-header-cell *matHeaderCellDef>{{ "customContentOne" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'customContentOne' | translate"
            [innerHTML]="domSanitizer.bypassSecurityTrustHtml(row.customTaskContentOne)"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="customTaskContentTwo">
          <th mat-header-cell *matHeaderCellDef>{{ "customContentTwo" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'customContentTwo' | translate"
            [innerHTML]="domSanitizer.bypassSecurityTrustHtml(row.customTaskContentTwo)"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="customTaskContentThree">
          <th mat-header-cell *matHeaderCellDef>{{ "customContentThree" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'customContentThree' | translate"
            [innerHTML]="domSanitizer.bypassSecurityTrustHtml(row.customTaskContentThree)"
            (click)="taskRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="justify-content-center">
            {{ "actions" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [attr.data-label]="'actions' | translate"
            class="text-center"
          >
            <mat-icon *ngIf="type === 'my'" svgIcon="unclaim" (click)="onUnclaim(row)"></mat-icon>
            <mat-icon
              class="ms-1"
              *ngIf="isUserInternal(row)"
              svgIcon="caseLog"
              [title]="'caseLogs' | translate"
              (click)="onViewCaseLogs(row)"
            ></mat-icon>
            <span class="material-icons ms-1" (click)="onTimeline(row)"> timeline </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
      </table>
      <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="rightMenu"
></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onUnclaim(item.data)">{{ "unclaim" | translate }}</button>
  </ng-template>
</mat-menu>
