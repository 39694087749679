<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ title | translate }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <mat-tab-group (selectedTabChange)="clicked($event)">
      <mat-tab label="{{ 'activeProcesses' | translate }}">
        <processes-table
          #processTable
          [processList]="activeProcesses$ | async"
          [type]="processTarget"
        >
        </processes-table>
      </mat-tab>
      <mat-tab label="{{ 'completedProcesses' | translate }}">
        <processes-table
          #processTable
          [processList]="completedProcesses"
          [completed]="true"
          [type]="processTarget"
        >
        </processes-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
