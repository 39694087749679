<div *ngIf="false" class="row">
  <div class="col-sm-4">
    <br />
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'matTableFilter' | translate }}"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-7"></div>
  <div class="col-sm-1">
    <!-- <a class="btn btn-secondary add-event-btn" (click)="addEvent()">+</a> -->
  </div>
</div>

<div class="row mt-4">
  <div class="col-sm-12">
    <div class="d-flex justify-content-start">
      <div>
        <gem-filter
          [title]="'buid' | translate"
          [searchLabel]="'buidSearch' | translate"
          [options]="buidFilterOptions$ | async"
          [value]="getSelectedFilters('buid')"
          [reset]="reset"
          (change)="onFilterChange($event, 'buid')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'partner' | translate"
          [searchLabel]="'partnerSearch' | translate"
          [options]="partnerFilterOptions$ | async"
          [value]="getSelectedFilters('partner')"
          [reset]="reset"
          (change)="onFilterChange($event, 'partner')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'partnerType' | translate"
          [searchLabel]="'partnerTypeSearch' | translate"
          [options]="partnerTypeFilterOptions$ | async"
          [value]="getSelectedFilters('partnerType')"
          [reset]="reset"
          (change)="onFilterChange($event, 'partnerType')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'partnerRole' | translate"
          [searchLabel]="'partnerRoleSearch' | translate"
          [options]="partnerRoleFilterOptions$ | async"
          [value]="getSelectedFilters('role')"
          [reset]="reset"
          (change)="onFilterChange($event, 'role')"
        ></gem-filter>
      </div>
      <div class="ms-2">
        <gem-filter
          [title]="'processName' | translate"
          [searchLabel]="'processNameSearch' | translate"
          [options]="processNameFilterOptions$ | async"
          [value]="getSelectedFilters('name')"
          [reset]="reset"
          (change)="onFilterChange($event, 'name')"
        ></gem-filter>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="md-chips d-flex">
      <div *ngFor="let selectedFilter of selectedFilters | keyvalue" class="d-flex">
        <div *ngFor="let sf of selectedFilter.value" class="d-flex">
          <div class="md-chip md-chip-clickable">
            <span>{{ sf.label | translate }}</span>
            <button
              type="button"
              class="md-chip-remove"
              (click)="onRemoveFilter(selectedFilter.key, sf)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="mat-elevation-z8 mat-table-responsive-container">
      <table
        mat-table
        matSort
        matTableResponsive
        [dataSource]="dataSource"
        (matSortChange)="onMatSortChange($event)"
      >
        <ng-container matColumnDef="buid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "buid" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.buid | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="partner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partner" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.partner | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="partnerType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "partnerType" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.partnerType | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "processOwner" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.owner | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="processName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "processName" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.name | translate | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="currentActvityName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "currentActvityName" | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.currentActvityName | translate | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="assignee">
          <th mat-header-cell *matHeaderCellDef>{{ "assignee" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.assignee | highlightSearch : filterText"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="createdTimeStamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "createdTimeStamp" | translate }}
          </th>
          <td mat-cell *matCellDef="let row" (click)="processRowClicked(row)">
            {{ row.created | date : "dd.MM.YYYY HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ (completed ? "completedOn" : "lastUpdate") | translate }}
          </th>
          <td mat-cell *matCellDef="let row" (click)="processRowClicked(row)">
            {{ row.lastUpdate | date : "dd.MM.YYYY HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="customProcessContentOne">
          <th mat-header-cell *matHeaderCellDef>{{ "customContentOne" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="domSanitizer.bypassSecurityTrustHtml(row.customProcessContentOne)"
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="customProcessContentTwo">
          <th mat-header-cell *matHeaderCellDef>{{ "customContentTwo" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="
              domSanitizer.bypassSecurityTrustHtml(
                row.customProcessContentTwo | highlightSearch : filterText
              )
            "
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="customProcessContentThree">
          <th mat-header-cell *matHeaderCellDef>{{ "customContentThree" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="
              domSanitizer.bypassSecurityTrustHtml(
                row.customProcessContentThree | highlightSearch : filterText
              )
            "
            (click)="processRowClicked(row)"
          ></td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="d-flex flex-row">
              <mat-icon
                *ngIf="isUserInternal(row)"
                svgIcon="caseLog"
                [title]="'caseLogs' | translate"
                (click)="onViewCaseLogs(row)"
              ></mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
