import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromStore from "../store";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { TokenHelperService } from "gematik-form-library";
import { Group } from "../services";
import { SECURITY_GROUP_BASIC } from "../services/uwl/security.service";

@Injectable()
export class AuthorizationGuard  {
  constructor(
    private store: Store<fromStore.UwlState>,
    private tokenHelperService: TokenHelperService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkStore().pipe(
      switchMap(() => {
        return this.hasGroup(SECURITY_GROUP_BASIC).pipe(
          tap((access) => {
            if (!access) {
              this.router.navigate(["noaccess"]);
            }
          }),
        );
      }),
    );
  }

  hasGroup(name: string): Observable<boolean> {
    return this.store.select(fromStore.getGroupsEntities).pipe(
      map((entities: { [key: string]: Group }) => !!entities[name]),
      take(1),
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getGroupsLoaded).pipe(
      tap((loaded) => {
        const groupIds = this.tokenHelperService.getGroups();
        if (!loaded && groupIds) {
          this.store.dispatch(new fromStore.LoadGroups(groupIds));
        }
      }),
      filter((loaded) => loaded),
      take(1),
    );
  }
}
