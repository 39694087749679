<div theme>
  <mat-sidenav-container class="pip-container">
    <mat-sidenav
      *ngIf="isLoggedIn() && (isAuthorized | async)"
      #sidenav
      mode="side"
      fixedInViewport="false"
      [mode]="(isHighRes | async)?.matches ? 'side' : 'over'"
      [opened]="(isHighRes | async)?.matches"
    >
      <sidenav-content [sidenav]="this.sidenav" (closeSidenav)="closeSidenav()"></sidenav-content>
    </mat-sidenav>
    <mat-sidenav-content>
      <gem-toolbar></gem-toolbar>
      <sidenav-extension
        *ngIf="isLoggedIn()"
        [menuIcon]="menuIcon"
        (menuClicked)="toggleSideNav()"
      ></sidenav-extension>

      <div #container *ngIf="isLoggedIn()" class="container-fluid">
        <div *ngIf="isLoading$ | async">
          <gem-loading-spinner></gem-loading-spinner>
        </div>
        <router-outlet></router-outlet>
      </div>

      <!-- <div class="mt-3 mb-3"><gem-footer></gem-footer></div> -->
      <div class="mt-3 mb-3 row gib-flex-center">
        <div class="col-12 max-width-1000 text-center">
          <gem-footer></gem-footer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
