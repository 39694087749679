import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccessDeniedComponent } from "./pages/access-denied/access-denied.component";
import { EndpointConfigPageComponent } from "./pages/endpoint-config-page/endpoint-config-page.component";
import { HelpConfigPageComponent } from "./pages/help-config-page/help-config-page.component";
import { IncidentsPageComponent } from "./pages/incidents-page/incidents-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { OauthRedirectComponent } from "./pages/oauth-redirect/oauth-redirect.component";
import { ProcessesPageComponent } from "./pages/processes-page/processes-page.component";
import { EndpointResolver } from "./pages/resolvers/endpoint.resolver";
import { TasksPageComponent } from "./pages/tasks-page/tasks-page.component";
import { TemplatesPageComponent } from "./pages/templates-page/templates-page.component";

// guards
import * as fromGuards from "./guards";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/tasks/my",
    pathMatch: "full",
  },
  { path: "oauth", component: OauthRedirectComponent },
  {
    path: "tasks/:taskTarget",
    component: TasksPageComponent,
    data: { component: "Tasks" },
    canActivate: [fromGuards.AuthorizationGuard],
  },
  {
    path: "processes/:processTarget",
    component: ProcessesPageComponent,
    data: { component: "Processes" },
    canActivate: [fromGuards.AuthorizationGuard],
  },
  {
    path: "incidents",
    component: IncidentsPageComponent,
    canActivate: [fromGuards.AuthorizationGuard, fromGuards.AdminGuard],
  },
  {
    path: "endpoints-config",
    component: EndpointConfigPageComponent,
    data: { component: "Endpoints" },
    canActivate: [fromGuards.AuthorizationGuard, fromGuards.AdminGuard],
  },
  {
    path: "templates",
    component: TemplatesPageComponent,
    data: { component: "Templates" },
    canActivate: [fromGuards.AuthorizationGuard, fromGuards.AdminGuard],
  },
  {
    path: "help-config",
    component: HelpConfigPageComponent,
    data: { component: "Help Config" },
    canActivate: [fromGuards.AuthorizationGuard, fromGuards.AdminGuard],
  },
  { path: "noaccess", component: AccessDeniedComponent },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [EndpointResolver],
})
export class AppRoutingModule {}
