import { TaskService } from "./camunda/task.service";
import { ProcessService } from "./camunda/process.service";
import { IncidentService } from "./camunda/incident.service";
import { UwlService } from "./uwl/uwl.service";
import { TemplateService } from "./uwl/template.service";
import { HelpService } from "./uwl/help.service";
import { SecurityService } from "./uwl/security.service";

export const services: any[] = [
  UwlService,
  TaskService,
  ProcessService,
  IncidentService,
  TemplateService,
  HelpService,
  SecurityService,
];

export * from "./uwl/uwl.service";
export * from "./camunda/task.service";
export * from "./camunda/process.service";
export * from "./camunda/incident.service";
export * from "./uwl/template.service";
export * from "./uwl/help.service";
export * from "./uwl/security.service";
