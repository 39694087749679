import { Action } from "@ngrx/store";
import { GematikProcess } from "../../models/gematik-process.model";

// Load All Active Processes
export const LOAD_ALL_ACTIVE_PROCESSES = "[Processes] Load All Active Processes";
export const LOAD_ALL_ACTIVE_PROCESSES_SUCCESS = "[Processes] Load All Active Processes Success";
export const LOAD_ALL_ACTIVE_PROCESSES_FAIL = "[Processes] Load All Active Processes Fail";

export class LoadAllActiveProcesses implements Action {
  readonly type = LOAD_ALL_ACTIVE_PROCESSES;
}

export class LoadAllActiveProcessesSuccess implements Action {
  readonly type = LOAD_ALL_ACTIVE_PROCESSES_SUCCESS;
  constructor(
    public payload: { myProcesses: GematikProcess[]; subscriberProcesses: GematikProcess[] },
  ) {}
}

export class LoadAllActiveProcessesFail implements Action {
  readonly type = LOAD_ALL_ACTIVE_PROCESSES_FAIL;
  constructor(public payload: any) {}
}

// Load Active Processes
export const LOAD_ACTIVE_PROCESSES = "[Processes] Load Active Processes";
export const LOAD_ACTIVE_PROCESSES_SUCCESS = "[Processes] Load Active Processes Success";
export const LOAD_ACTIVE_PROCESSES_FAIL = "[Processes] Load Active Processes Fail";

export class LoadActiveProcesses implements Action {
  readonly type = LOAD_ACTIVE_PROCESSES;
  constructor(public payload: string) {}
}

export class LoadActiveProcessesSuccess implements Action {
  readonly type = LOAD_ACTIVE_PROCESSES_SUCCESS;
  constructor(
    public payload: { myProcesses: GematikProcess[]; subscriberProcesses: GematikProcess[] },
    public backendUrl: string,
  ) {}
}

export class LoadActiveProcessesFail implements Action {
  readonly type = LOAD_ACTIVE_PROCESSES_FAIL;
  constructor(public payload: any) {}
}

// Load My Completed Processes
export const LOAD_MY_COMPLETED_PROCESSES = "[Processes] Load My Completed Processes";
export const LOAD_MY_COMPLETED_PROCESSES_SUCCESS =
  "[Processes] Load My Completed Processes Success";
export const LOAD_MY_COMPLETED_PROCESSES_FAIL = "[Processes] Load My Completed Processes Fail";

export class LoadMyCompletedProcesses implements Action {
  readonly type = LOAD_MY_COMPLETED_PROCESSES;
}

export class LoadMyCompletedProcessesSuccess implements Action {
  readonly type = LOAD_MY_COMPLETED_PROCESSES_SUCCESS;
  constructor(
    public payload: { myProcesses: GematikProcess[]; subscriberProcesses: GematikProcess[] },
  ) {}
}

export class LoadMyCompletedProcessesFail implements Action {
  readonly type = LOAD_MY_COMPLETED_PROCESSES_FAIL;
  constructor(public payload: any) {}
}

// Load Subscriber Completed Processes
export const LOAD_SUBSCRIBER_COMPLETED_PROCESSES =
  "[Processes] Load Subscriber Completed Processes";
export const LOAD_SUBSCRIBER_COMPLETED_PROCESSES_SUCCESS =
  "[Processes] Load Subscriber Completed Processes Success";
export const LOAD_SUBSCRIBER_COMPLETED_PROCESSES_FAIL =
  "[Processes] Load Subscriber Completed Processes Fail";

export class LoadSubscriberCompletedProcesses implements Action {
  readonly type = LOAD_SUBSCRIBER_COMPLETED_PROCESSES;
}

export class LoadSubscriberCompletedProcessesSuccess implements Action {
  readonly type = LOAD_SUBSCRIBER_COMPLETED_PROCESSES_SUCCESS;
  constructor(
    public payload: { myProcesses: GematikProcess[]; subscriberProcesses: GematikProcess[] },
  ) {}
}

export class LoadSubscriberCompletedProcessesFail implements Action {
  readonly type = LOAD_SUBSCRIBER_COMPLETED_PROCESSES_FAIL;
  constructor(public payload: any) {}
}

export type ProcessActions =
  | LoadAllActiveProcesses
  | LoadAllActiveProcessesSuccess
  | LoadAllActiveProcessesFail
  | LoadActiveProcesses
  | LoadActiveProcessesSuccess
  | LoadActiveProcessesFail
  | LoadMyCompletedProcesses
  | LoadMyCompletedProcessesSuccess
  | LoadMyCompletedProcessesFail
  | LoadSubscriberCompletedProcesses
  | LoadSubscriberCompletedProcessesSuccess
  | LoadSubscriberCompletedProcessesFail;
