import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { GematikProcessDto } from "gematik-task-api";
import { ProcessesTableComponent } from "../components/processes-table/processes-table.component";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { GematikProcess } from "../../models/gematik-process.model";

import { Store } from "@ngrx/store";
import * as fromStore from "../../store";

@Component({
  selector: "app-processes-page",
  templateUrl: "./processes-page.component.html",
  styleUrls: ["./processes-page.component.scss"],
})
export class ProcessesPageComponent implements OnInit, OnDestroy {
  @ViewChild("processesTable") eventTable: ProcessesTableComponent;
  processesAsync: GematikProcessDto[];
  completedProcessesAsync: GematikProcessDto[];
  processes: GematikProcessDto[];
  completedProcesses: GematikProcessDto[];
  componentName = "ProcessesPageComponent";
  title: string;
  processTarget: string;

  showButtons = true;
  task: any;

  activeTab: number = 0;

  activeProcesses$: Observable<GematikProcessDto[]>;

  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslateService,
    private store: Store<fromStore.UwlState>,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("processTarget")) {
          this.processTarget = paramMap.get("processTarget");
          this.initTasks();
        }
      }),
    );
  }

  private initTasks() {
    if (this.activeTab === 0) {
      if (this.processTarget === "my") {
        this.activeProcesses$ = this.store.select(fromStore.getActiveMyProcesses);
      } else {
        this.activeProcesses$ = this.store.select(fromStore.getActiveSubscriberProcesses);
      }
    } else if (this.activeTab === 1) {
      if (this.processTarget === "my") {
        this.store.dispatch(new fromStore.LoadMyCompletedProcesses());
        this.subscriptions.push(
          this.store.select(fromStore.getCompletedMyProcesses).subscribe((processes) => {
            this.mapProcesses(processes);
          }),
        );
      } else {
        this.store.dispatch(new fromStore.LoadSubscriberCompletedProcesses());
        this.subscriptions.push(
          this.store.select(fromStore.getCompletedSubscriberProcesses).subscribe((processes) => {
            this.mapProcesses(processes);
          }),
        );
      }
    }
    this.title = "uwl-processes-" + this.processTarget;
  }

  private mapProcesses(processes: GematikProcess[]) {
    const processesArr: GematikProcess[] = [];
    processes.forEach((process) => {
      const p: GematikProcess = {
        ...process,
      };
      processesArr.push(p);
    });
    this.completedProcesses = processesArr;
  }

  getTaskIdFilter() {
    return this.processTarget;
  }

  pushProcesses(tasks: any[]) {
    for (const task of tasks) {
      this.processesAsync.push(task);
    }
  }

  pushCompletedProcesses(tasks: any[]) {
    for (const task of tasks) {
      this.completedProcessesAsync.push(task);
    }
  }

  openFormForTask(task: any, taskTarget: string) {}

  clicked(event: any) {
    this.activeTab = event.index;
    window.scroll(0, 0);
    this.initTasks();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
