<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "name" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "url" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.url }}</td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "roles" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.roles }}</td>
    </ng-container>

    <!-- <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "status" | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <i
          *ngIf="row.webSocketIsActive"
          class="material-icons"
          style="color: green"
          >check</i
        >
        <i
          *ngIf="!row.webSocketIsActive"
          class="material-icons"
          style="color: red; cursor: pointer;"
          [ngbTooltip]="'RESTART' | translate"
          (click)="restartWebSocket(row)"
          >close</i
        >
      </td>
    </ng-container> -->

    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
        <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectEntity(row)"
          >edit</i
        >
        <i
          class="material-icons"
          [ngbTooltip]="'DELETE' | translate"
          (click)="openDeleteEntityConfirmation(row)"
          >delete</i
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
</div>
