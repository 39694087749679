<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "name" | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="buttons">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
        <i class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectEntity(row)"
          >edit</i
        >
        <i
          class="material-icons"
          [ngbTooltip]="'DELETE' | translate"
          (click)="openDeleteEntityConfirmation(row)"
          >delete</i
        >
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [pageSize]="100"
    [pageSizeOptions]="[10, 50, 100, 200]"
    [length]="length"
  ></mat-paginator>
</div>
