import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

export interface TimelineResponse {
  history: Timeline[];
  currentActivity: Timeline[];
  milestones: Timeline[];
}

export interface Timeline {
  activityId: string;
  label: string;
  description: string;
  created: Date;
  completed: Date;
}

@Injectable({
  providedIn: "root",
})
export class TimelineService {
  constructor(private http: HttpClient) {}

  getTimeline(endpoint: string, buid: string, lang: string): Observable<TimelineResponse> {
    return this.http.get<TimelineResponse>(`${endpoint}/public/timeline/${buid}?lang=${lang}`);
  }
}
