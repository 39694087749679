import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { NavigationEnd, Router } from "@angular/router";
import { EnvService } from "gematik-form-library";
import { TokenHelperService } from "gematik-form-library";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { SidenavService } from "./services/sidenav.service";
import { delay, filter, map, take, tap, withLatestFrom } from "rxjs/operators";
import { Endpoint } from "./services/uwl/uwl.service";
import { GematikTaskApiService, GematikTranslationDto } from "gematik-task-api";
import { RxStompService } from "./services/stomp/rx-stomp.service";
import { Message } from "@stomp/stompjs";

import * as fromStore from "./store";
import { Store } from "@ngrx/store";
import { LoadingService } from "gematik-shared";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild("container") container: ElementRef;
  isHighRes: Observable<BreakpointState> = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
  ]);
  isHighResMode: boolean;
  menuIcon = "chevron_left";
  actualComponentRef: any;
  isAuthorized: Observable<boolean>;

  // Subscriptions
  subscriptions: Subscription[] = [];

  isLoading$: Observable<boolean>;

  constructor(
    private translationService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private oauthService: OAuthService,
    private router: Router,
    private env: EnvService,
    private tokenHelper: TokenHelperService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sidenavService: SidenavService,
    private tokenHelperService: TokenHelperService,
    private taskService: GematikTaskApiService,
    private rxStompService: RxStompService,
    private store: Store<fromStore.UwlState>,
    private loadingService: LoadingService,
  ) {
    if (this.tokenHelperService.isExpired()) {
      this.tokenHelperService.logout();
    }

    const authConfig: AuthConfig = {
      issuer: "https://login.microsoftonline.com/" + env.oauthTennantId + "/v2.0",
      redirectUri: window.location.origin + "/oauth",
      clientId: env.oauthClientId,
      responseType: "code",
      clearHashAfterLogin: false,
      strictDiscoveryDocumentValidation: false,
      scope: "openid " + env.oauthApplicationIdUri + "/user_impersonation",
    };
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case "token_received":
          tokenHelper.saveToken(this.oauthService.getAccessToken());
          this.router.navigate([decodeURIComponent(this.oauthService.state)]);
          this.initStore();
          break;
        case "code_error":
          tokenHelper.saveToken("");
          this.router.navigate(["/noaccess"]);
          break;
      }
    });

    const lang = localStorage.getItem("gem-locale");
    if (lang) {
      if (lang === "en") {
        translationService.use("en");
      } else {
        translationService.use("de");
      }
    } else {
      translationService.use("de");
    }
    this.matIconRegistry.addSvgIcon(
      "gem-users",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-users.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/settings.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "processes",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/processes.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "signpost",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signpost.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-approval-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/Zustimmung_Blau_gematik.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "unclaim",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/unclaim.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "caseLog",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/case-log.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-refresh",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/refresh.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-feedback",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-feedback.svg"),
    );
  }

  ngOnInit() {
    if (this.oauthService.hasValidAccessToken()) {
      this.initStore();
    }

    this.subscriptions.push(
      this.breakpointObserver
        .observe([Breakpoints.XLarge, Breakpoints.Large])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.menuIcon = "chevron_left";
            this.isHighResMode = true;
          } else {
            this.menuIcon = "menu";
            this.isHighResMode = false;
          }
        }),
    );
    window.addEventListener("wheel", (event) => this.doNothing());

    this.subscriptions.push(
      this.rxStompService.watch("/topic/gematik").subscribe((message: Message) => {
        const body = JSON.parse(message.body);
        const type = body.type;
        const eventName = body.event.eventName;
        if (type === "INSTANCE") {
          this.store.dispatch(new fromStore.LoadActiveProcesses(body.backendUrl));
        } else if (type === "TASK") {
          if (eventName === "create") {
            this.store.dispatch(new fromStore.LoadOpenTasks(body.backendUrl));
            this.store.dispatch(new fromStore.LoadActiveProcesses(body.backendUrl));
          } else if (eventName === "assignment") {
            this.store.dispatch(new fromStore.LoadOpenTasks(body.backendUrl));
          } else if (eventName === "complete") {
            this.store.dispatch(new fromStore.LoadOpenTasks(body.backendUrl));
            this.store.dispatch(new fromStore.LoadActiveProcesses(body.backendUrl));
          }
        } else if (type === "INCIDENT") {
          this.store.dispatch(new fromStore.LoadIncidents(body.backendUrl));
        }
      }),
    );
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
    this.router.events
      .pipe(
        withLatestFrom(this.isHighRes),
        filter(([a, b]) => b && a instanceof NavigationEnd),
      )
      .subscribe((res) => {
        let isLowRes = !res[1].matches;
        if (isLowRes) {
          this.sidenav?.close();
        }
      });
    this.isLoading$ = this.loadingService.isLoading.pipe(delay(0));
  }

  toggleSideNav() {
    this.sidenav.toggle();
    if (this.isHighResMode) {
      if (this.menuIcon === "chevron_left") {
        this.menuIcon = "menu";
      } else {
        this.menuIcon = "chevron_left";
      }
    }
  }
  closeSidenav() {
    this.sidenav.close();
  }

  doNothing() {
    return;
  }

  isLoggedIn() {
    return this.tokenHelper.isLoggedIn();
  }

  private initStore(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.store.dispatch(new fromStore.LoadAllOpenTasks());
      this.store.dispatch(new fromStore.LoadAllActiveProcesses());
      this.store.dispatch(new fromStore.LoadEndpoints());
      this.store.dispatch(new fromStore.LoadHelp());

      // Only admins can query incidents (purely for performance optimization)
      // Same authorization check is performed at the backend
      this.subscriptions.push(
        this.store.select(fromStore.isUserAdmin).subscribe((isAdmin) => {
          if (isAdmin) {
            this.store.dispatch(new fromStore.LoadAllIncidents());
          }
        }),
      );

      this.isAuthorized = this.store.select(fromStore.isUserAuthorized);

      this.subscriptions.push(
        this.store.select(fromStore.getEndpoints).subscribe((endpoints) => {
          endpoints.forEach((endpoint) => this.getTranslations(endpoint));
        }),
      );
    }
  }

  private getTranslations(endpoint: Endpoint) {
    this.taskService.getTranslations(endpoint.url).subscribe((resT) => {
      let translations: GematikTranslationDto[] = resT.body;
      for (const translation of translations) {
        this.translationService.set(translation.key, translation.value, translation.lang);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
