<div class="row">
  <div class="col-sm-4">
    <br />
    <mat-form-field>
      <input
        matInput
        (keyup)="applyFilter($event.target.value)"
        placeholder="{{ 'matTableFilter' | translate }}"
      />
    </mat-form-field>
  </div>
  <div class="col-sm-7"></div>
  <div class="col-sm-1">
    <!-- <a class="btn btn-secondary add-event-btn" (click)="addEvent()">+</a> -->
  </div>

  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onMatSortChange($event)">
        <ng-container matColumnDef="incidentMessage">
          <th mat-header-cell *matHeaderCellDef>{{ "message" | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [innerHTML]="row.incidentMessage | highlightSearch : filterText"
          ></td>
        </ng-container>

        <ng-container matColumnDef="processInstanceId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "processInstance" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.processInstanceId }}
          </td>
        </ng-container>

        <ng-container matColumnDef="incidentTimestamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "timestamp" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.incidentTimestamp | date : "short" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="activityId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "activity" | translate }}</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.activityId | translate"></td>
        </ng-container>

        <ng-container matColumnDef="failedActivityId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "failingActivity" | translate }}
          </th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.failedActivityId | translate"></td>
        </ng-container>

        <ng-container matColumnDef="incidentType">
          <th mat-header-cell *matHeaderCellDef>{{ "type" | translate }}</th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row.incidentType | translate"></td>
        </ng-container>

        <ng-container matColumnDef="annotation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "annotation" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.annotation | date : "dd.MM.YYYY HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="diagram">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "diagram" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div (click)="getDiagram(row)" style="cursor: pointer">
              <mat-icon>account_tree</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="stacktrace">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "stacktrace" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div (click)="getStacktrace(row)" style="cursor: pointer">
              <mat-icon>bug_report</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="retry">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "retry" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div (click)="retryIncident(row)" style="cursor: pointer">
              <mat-icon>refresh</mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [pageSize]="50" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>
