import * as fromTasks from "../actions/task.actions";
import { GematikTask } from "../../models/gematik-task.model";

export interface TaskState {
  groupTasks: {
    entities: { [id: string]: GematikTask };
  };
  myTasks: {
    entities: { [id: string]: GematikTask };
  };
  loading: boolean;
  loaded: boolean;
}

export const initialState: TaskState = {
  groupTasks: {
    entities: {},
  },
  myTasks: {
    entities: {},
  },
  loading: false,
  loaded: false,
};

export function taskOpenReducer(state = initialState, action: fromTasks.TaskActions): TaskState {
  switch (action.type) {
    case fromTasks.LOAD_OPEN_TASKS:
    case fromTasks.LOAD_ALL_OPEN_TASKS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromTasks.LOAD_ALL_OPEN_TASKS_SUCCESS: {
      const tasks = action.payload;
      const groupTaskEntities = tasks.groupTasks.reduce(
        (entities: { [id: string]: GematikTask }, task: GematikTask) => {
          return {
            ...entities,
            [task.id]: task,
          };
        },
        {
          ...state.groupTasks.entities,
        },
      );
      const myTaskEntities = tasks.myTasks.reduce(
        (entities: { [id: string]: GematikTask }, task: GematikTask) => {
          return {
            ...entities,
            [task.id]: task,
          };
        },
        {
          ...state.myTasks.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        groupTasks: {
          entities: groupTaskEntities,
        },
        myTasks: {
          entities: myTaskEntities,
        },
      };
    }
    case fromTasks.LOAD_OPEN_TASKS_FAIL:
    case fromTasks.LOAD_ALL_OPEN_TASKS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
    case fromTasks.LOAD_OPEN_TASKS_SUCCESS: {
      const tasks = action.payload;

      // remove
      const myTasks = Object.entries(state.myTasks.entities)
        .filter((entity) => entity[1].backendUrl !== action.backendUrl)
        .map((entity) => entity[1]);

      const groupTasks = Object.entries(state.groupTasks.entities)
        .filter((entity) => entity[1].backendUrl !== action.backendUrl)
        .map((entity) => entity[1]);

      // add
      myTasks.push(...action.payload.myTasks);
      groupTasks.push(...action.payload.groupTasks);

      // reduce
      const myTaskEntities = myTasks.reduce(
        (entities: { [id: string]: GematikTask }, task: GematikTask) => {
          return {
            ...entities,
            [task.id]: task,
          };
        },
        {},
      );

      const groupTaskEntities = groupTasks.reduce(
        (entities: { [id: string]: GematikTask }, task: GematikTask) => {
          return {
            ...entities,
            [task.id]: task,
          };
        },
        {},
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        groupTasks: {
          entities: groupTaskEntities,
        },
        myTasks: {
          entities: myTaskEntities,
        },
      };
    }
  }
  return state;
}

export const getGroupTaskEntities = (state: TaskState) => state.groupTasks.entities;
export const getMyTaskEntities = (state: TaskState) => state.myTasks.entities;

export function taskCompletedReducer(
  state = initialState,
  action: fromTasks.TaskActions,
): TaskState {
  switch (action.type) {
    case fromTasks.LOAD_GROUP_COMPLETED_TASKS:
    case fromTasks.LOAD_MY_COMPLETED_TASKS: {
      return {
        ...state,
        loading: true,
      };
    }
    case fromTasks.LOAD_MY_COMPLETED_TASKS_SUCCESS: {
      const tasks = action.payload;
      const myTaskEntities = tasks.myTasks.reduce(
        (entities: { [id: string]: GematikTask }, task: GematikTask) => {
          return {
            ...entities,
            [task.id]: task,
          };
        },
        {
          ...state.myTasks.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        myTasks: {
          entities: myTaskEntities,
        },
      };
    }
    case fromTasks.LOAD_GROUP_COMPLETED_TASKS_SUCCESS: {
      const tasks = action.payload;
      const groupTaskEntities = tasks.groupTasks.reduce(
        (entities: { [id: string]: GematikTask }, task: GematikTask) => {
          return {
            ...entities,
            [task.id]: task,
          };
        },
        {
          ...state.myTasks.entities,
        },
      );
      return {
        ...state,
        loading: false,
        loaded: true,
        groupTasks: {
          entities: groupTaskEntities,
        },
      };
    }
    case fromTasks.LOAD_GROUP_COMPLETED_TASKS_FAIL:
    case fromTasks.LOAD_MY_COMPLETED_TASKS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }
  }
  return state;
}
