import { Injectable, OnDestroy, OnInit } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";

import { Subscription, take } from "rxjs";

import { Timeline, TimelineResponse, TimelineService } from "../timeline/timelines.service";
import { TranslateService } from "@ngx-translate/core";
import { GemTimelineDialogComponent } from "gematik-form-library";

@Injectable({
  providedIn: "root",
})
export class UwlUIservice implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private timelineService: TimelineService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {}

  showTimeline(backendUrl: string, buid: string): void {
    const sub = this.timelineService
      .getTimeline(backendUrl, buid, this.translateService.currentLang)
      .pipe(take(1))
      .subscribe((res) => {
        const timeline: TimelineResponse = res;
        if (timeline) {
          const events: any[] = [];
          const history: Timeline[] = timeline.history;
          const currentActivity: Timeline[] = timeline.currentActivity;
          const milestones: Timeline[] = timeline.milestones;

          if (milestones.length === 0) {
            alert("TODO");
          } else {
            history.forEach((h) =>
              events.push({
                label: h.label,
                description: h.description,
                date: h.created ? h.created : h.completed,
                status: "CLOSED",
              }),
            );
            currentActivity.forEach((c) =>
              events.push({
                label: c.label,
                description: c.description,
                date: c.created ? c.created : c.completed,
                status: "CURRENT",
              }),
            );
            milestones.forEach((m) =>
              events.push({
                label: m.label,
                description: m.description,
                date: m.created ? m.created : m.completed,
                status: "OPEN",
              }),
            );
          }
          const dialogRef = this.dialog.open(GemTimelineDialogComponent, {
            width: "40%",
            data: { events },
          });
        }
      });
    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
