import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvService } from "gematik-form-library";
import { GematikTask } from "../../models/gematik-task.model";

export interface TaskResponse {
  myTasks: GematikTask[];
  groupTasks: GematikTask[];
}

@Injectable({
  providedIn: "root",
})
export class TaskService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getAllOpen(): Observable<TaskResponse> {
    return this.http.get<TaskResponse>(this.env.backendUrl + "/uwl/task/all/open");
  }

  getOpen(backendUrl: string): Observable<TaskResponse> {
    const payload = {
      backendUrl,
    };
    return this.http.post<TaskResponse>(`${this.env.backendUrl}/uwl/task/open`, payload);
  }

  getAllMyCompleted(): Observable<TaskResponse> {
    return this.http.get<TaskResponse>(this.env.backendUrl + "/uwl/task/all/my/completed");
  }

  getAllGroupCompleted(): Observable<TaskResponse> {
    return this.http.get<TaskResponse>(this.env.backendUrl + "/uwl/task/all/group/completed");
  }
}
