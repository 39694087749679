import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormHelperService, TokenHelperService, ValueLabel } from "gematik-form-library";
import { GematikTaskDto } from "gematik-task-api";
import { MatDialog } from "@angular/material/dialog";
import { CompletedTaskDetailsDialogComponent } from "./completed-task-details-dialog/completed-task-details-dialog.component";
import { Observable, Subscription, of } from "rxjs";
import { NavigationEnd, NavigationStart, Router, Scroll } from "@angular/router";

import { Store } from "@ngrx/store";
import * as fromStore from "../../../store";
import { map } from "rxjs/operators";
import { GematikTableFilter } from "../../../models/gematik-table-filter.model";

@Component({
  selector: "completed-task-table",
  templateUrl: "./completed-task-table.component.html",
  styleUrls: ["./completed-task-table.component.scss"],
})
export class CompletedTaskTableComponent implements OnInit, OnChanges, OnDestroy {
  tsks: any[];
  filterText: string = "";
  displayedColumns: string[] = [
    "buid",
    "partner",
    "partnerType",
    "taskApprover",
    "processName",
    "taskName",
    "completedtimeStamp",
    "taskOutcome",
    "actions",
  ];

  dataSource: MatTableDataSource<any>;

  @Output() taskSelected = new EventEmitter();
  @Input() set completedTasks(completedTasks: any[]) {
    this.tsks = completedTasks;
    // if (completedTasks && completedTasks.length > 0) {
    //   for (const task of completedTasks) {
    //     if (task.processName) {
    //       task.processName = this.translateService.instant(task.processName);
    //     }
    //     if (task.outcome) {
    //       task.outcome = this.translateService.instant(task.outcome);
    //     }
    //   }
    // }

    this.dataSource = new MatTableDataSource(completedTasks);
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.cdr.detectChanges();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  buidFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerTypeFilterOptions$: Observable<ValueLabel[]> = of([]);
  partnerRoleFilterOptions$: Observable<ValueLabel[]> = of([]);
  processNameFilterOptions$: Observable<ValueLabel[]> = of([]);
  taskNameFilterOptions$: Observable<ValueLabel[]> = of([]);

  selectedFilters: { [id: string]: ValueLabel[] } | any = {
    buid: [],
    partner: [],
    partnerType: [],
    role: [],
    processName: [],
    name: [],
  };

  reset: { search: boolean };

  subscriptions: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private formHelper: FormHelperService,
    private dialog: MatDialog,
    private tokenHelperService: TokenHelperService,
    private router: Router,
    private store: Store<fromStore.UwlState>,
  ) {}

  ngOnInit() {
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.reset = {
          ...this.reset,
          search: false,
        };
      } else if (event instanceof Scroll) {
        if (event.routerEvent instanceof NavigationEnd) {
          this.getFilters(event.routerEvent.url);
        }
      } else if (event instanceof NavigationEnd) {
        this.getFilters(event.url);
      }
    });
    this.subscriptions.push(sub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter();
  }

  private getFilters(url: string): void {
    this.selectedFilters = {
      buid: [],
      partner: [],
      partnerType: [],
      role: [],
      processName: [],
      name: [],
    };
    this.reset = {
      ...this.reset,
      search: true,
    };
    if (url === "/tasks/my") {
      this.buidFilterOptions$ = this.store.select(fromStore.getMyCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => {
            return { value: task.buid, label: task.buid };
          });
        }),
      );
      this.partnerFilterOptions$ = this.store.select(fromStore.getMyCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partner);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerTypeFilterOptions$ = this.store.select(fromStore.getMyCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partnerType);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerRoleFilterOptions$ = this.store.select(fromStore.getMyCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.role);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.processNameFilterOptions$ = this.store.select(fromStore.getMyCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.processName);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.taskNameFilterOptions$ = this.store.select(fromStore.getMyCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.name);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
    } else if (url === "/tasks/group") {
      this.buidFilterOptions$ = this.store.select(fromStore.getGroupCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => {
            return { value: task.buid, label: task.buid };
          });
        }),
      );
      this.partnerFilterOptions$ = this.store.select(fromStore.getGroupCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partner);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerTypeFilterOptions$ = this.store.select(fromStore.getGroupCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.partnerType);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.partnerRoleFilterOptions$ = this.store.select(fromStore.getGroupCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.role);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.processNameFilterOptions$ = this.store.select(fromStore.getGroupCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.processName);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
      this.taskNameFilterOptions$ = this.store.select(fromStore.getGroupCompletedTasks).pipe(
        map((tasks) => {
          return tasks.map((task) => task.name);
        }),
        map((values) => Array.from(new Set(values))),
        map((values) =>
          values.map((value) => {
            return { value, label: value };
          }),
        ),
      );
    }
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      // if (this.dataSource.paginator.hasPreviousPage()) {
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.lastPage();
        this.cdr.detectChanges();
      }
    }
  }

  taskRowClicked(completedTask: any) {
    const task: GematikTaskDto = completedTask;
    const dialogRef = this.dialog.open(CompletedTaskDetailsDialogComponent, {
      data: {
        task,
      },
    });
    this.taskSelected.emit(completedTask);
  }

  customFilterPredicate(data: any, filters: { [id: string]: ValueLabel[] }): boolean {
    let expression: string = "";
    Object.keys(filters).forEach((key, index, array) => {
      const vls: ValueLabel[] = filters[key];
      if (vls.length > 0) {
        let expr: string = "(";
        vls.forEach((vl, index, array) => {
          expr = expr + `data["${key}"].includes("${vl.value}")`;
          if (index !== array.length - 1) {
            expr = expr + " || ";
          } else {
            expr = expr + ")";
          }
        });
        expression = expression + expr + " && ";
      }
    });
    expression = expression.substring(0, expression.lastIndexOf("&&"));
    if (!expression) {
      return true;
    }
    return eval(expression);
  }

  onMatSortChange($event: any) {
    const sortedTasks = Object.create(this.tsks);
    if (["completedtimeStamp"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByCreatedDate);
    } else if (["priority", "taskName", "partner", "processName"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortByProperty($event.active));
    } else if (["task"].includes($event.active)) {
      sortedTasks.sort(this.formHelper.sortTasksByProperty($event.active));
    }

    if ($event.direction === "desc") {
      sortedTasks.reverse();
    }
    this.dataSource = new MatTableDataSource(sortedTasks);
    this.dataSource.filterPredicate = (row: any, filter: any) => {
      return this.customFilterPredicate(row, filter);
    };
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  isUserInternal(task: GematikTaskDto): boolean {
    return this.tokenHelperService.isUserInternal() && task.caseLogId !== null;
  }

  onViewCaseLogs(task: GematikTaskDto): void {
    const url: string = `${task.crmUrl}/index.php?module=Cases&action=DetailView&record=${task.caseLogId}`;
    window.open(url);
  }

  getSelectedFilters(key: string): ValueLabel[] {
    return this.selectedFilters[key];
  }

  onFilterChange(event: { checked: boolean; vl: ValueLabel }, key: string): void {
    const { checked, vl } = event;
    if (checked) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [key]: [...this.selectedFilters[key], vl],
      };
    } else {
      this.onRemoveFilter(key, vl);
    }
    this.filter();
  }

  onRemoveFilter(key: string, filter: ValueLabel): void {
    this.selectedFilters = {
      ...this.selectedFilters,
      [key]: this.getSelectedFilters(key).filter((f) => f !== filter),
    };
    this.filter();
  }

  private filter(): void {
    this.dataSource.filter = this.selectedFilters;
    if (this.dataSource.paginator) {
      const paginator = this.dataSource.paginator as MatPaginator;
      if (paginator.hasPreviousPage()) {
        this.dataSource.paginator.lastPage();
        this.cdr.detectChanges();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
