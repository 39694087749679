import { EndpointEffects } from "./endpoint.effects";
import { TaskEffects } from "./task.effects";
import { ProcessEffects } from "./process.effects";
import { IncidentEffects } from "./incident.effects";
import { TemplateEffects } from "./template.effects";
import { HelpEffects } from "./help.effects";
import { SecurityEffects } from "./security.effects";

export const effects: any[] = [
  EndpointEffects,
  TaskEffects,
  ProcessEffects,
  IncidentEffects,
  TemplateEffects,
  HelpEffects,
  SecurityEffects,
];

export * from "./endpoint.effects";
export * from "./task.effects";
export * from "./process.effects";
export * from "./incident.effects";
export * from "./template.effects";
export * from "./help.effects";
export * from "./security.effects";
