import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "highlightSearch",
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, search: string): string {
    if (search) {
      const valueStr = value + "";
      return "<div>" + valueStr.replace(new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + search + ")(?![^<>]*>)(?![^&;]+;)", "gi"), '<strong class=pip-seach-highlight">$1</strong>') + "</div>";
    }
    return value;
  }
}
