<div class="row">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-12">
        <h1>{{ title | translate }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <mat-tab-group #tabGroup (selectedTabChange)="clicked($event)">
      <mat-tab label="{{ 'openTasks' | translate }}">
        <task-table
          #taskTable
          [tasks]="openTasks$ | async"
          [type]="type"
          (taskSelected)="openFormForTask($event, taskTarget)"
          (taskCompleted)="refreshTasks($event)"
        >
        </task-table>
      </mat-tab>
      <mat-tab label="{{ 'completedTasks' | translate }}">
        <completed-task-table [completedTasks]="completedTasks"></completed-task-table>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
