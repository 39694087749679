import { Component, OnDestroy, OnInit } from "@angular/core";
import { TokenHelperService } from "gematik-form-library";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromStore from "../../store";

@Component({
  selector: "sidenav-content",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit, OnDestroy {
  treeElements: any[] = [];
  taskGroupCount$: Observable<number>;
  taskMyCount$: Observable<number>;
  processGroupCount$: Observable<number>;
  processMyCount$: Observable<number>;
  endpointsCount$: Observable<number>;
  incidentsCount$: Observable<number>;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(private tokenHelper: TokenHelperService, private store: Store<fromStore.UwlState>) {}

  ngOnInit(): void {
    this.taskGroupCount$ = this.store.select(fromStore.getOpenGroupTaskCount);
    this.taskMyCount$ = this.store.select(fromStore.getOpenMyTaskCount);
    this.processGroupCount$ = this.store.select(fromStore.getActiveSubscriberProcessCount);
    this.processMyCount$ = this.store.select(fromStore.getActiveMyProcessCount);
    this.endpointsCount$ = this.store.select(fromStore.getEndpointCount);
    this.incidentsCount$ = this.store.select(fromStore.getIncidentCount);
    this.treeElements = this.createTreeElements();
  }

  createTreeElements() {
    const elements = [];
    elements.push({
      rootLabel: "uwl-tasks",
      links: [
        { label: "uwl-tasks-my", route: "/tasks/my", badge: this.taskMyCount$ },
        { label: "uwl-tasks-group", route: "/tasks/group", badge: this.taskGroupCount$ },
      ],
      icon: "gem-users",
      treeElementOpened: true,
    });
    elements.push({
      rootLabel: "uwl-processes",
      links: [
        { label: "uwl-processes-my", route: "/processes/my", badge: this.processMyCount$ },
        {
          label: "uwl-processes-subscriber",
          route: "/processes/subscriber",
          badge: this.processGroupCount$,
        },
      ],
      icon: "processes",
      treeElementOpened: true,
    });
    elements.push({
      rootLabel: "uwl-help",
      links: [
        {
          label: "uwl-help-activities",
          external: "https://confluence.int.gematik.de/pages/viewpage.action?pageId=158434465",
          help: "uwl-help-activities",
        },
        {
          label: "uwl-help-support",
          external: "https://confluence.int.gematik.de/display/OP/Support",
          help: "uwl-help-support",
        },
        {
          label: "uwl-help-sl-review",
          external: "https://www.gematik.de/",
          help: "uwl-help-sl-review",
        },
        {
          label: "uwl-help-accreditation-request",
          external: "https://www.gematik.de/",
          help: "uwl-help-accreditation-request",
        },
      ],
      icon: "signpost",
    });

    this.subscriptions.push(
      this.store.select(fromStore.isUserAdmin).subscribe((isAdmin) => {
        if (isAdmin) {
          elements.push({
            rootLabel: "uwl-administration",
            links: [
              { label: "uwl-config", route: "/endpoints-config", badge: this.endpointsCount$ },
              { label: "incidents", route: "/incidents", badge: this.incidentsCount$ },
              { label: "templates", route: "/templates" },
              { label: "uwl-help", route: "/help-config" },
            ],
            icon: "settings",
          });
        }
      }),
    );

    // if (this.tokenHelper.containsRole("gematik.uwl.admin")) {
    //   elements.push({
    //     rootLabel: "uwl-administration",
    //     links: [
    //       { label: "uwl-config", route: "/endpoints-config", badge: this.endpointsCount$ },
    //       { label: "incidents", route: "/incidents", badge: this.incidentsCount$ },
    //       { label: "templates", route: "/templates" },
    //       { label: "uwl-help", route: "/help-config" },
    //     ],
    //     icon: "settings",
    //   });
    // }
    return elements;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
